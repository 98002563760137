import type { IconProps } from './types';

export const IconIntroduction3 = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_252_2474)'>
        <path
          d='M359.418 341.205L314.018 308.107L268.59 341.205L285.928 287.639L240.528 254.569H296.652L314.018 201.003L331.356 254.569H387.507L342.08 287.639L359.418 341.205Z'
          stroke={color}
          strokeWidth='16.331'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M412.004 162.217H216.032C210.019 162.217 205.145 167.092 205.145 173.105V369.076C205.145 375.089 210.019 379.964 216.032 379.964H412.004C418.016 379.964 422.891 375.089 422.891 369.076V173.105C422.891 167.092 418.016 162.217 412.004 162.217Z'
          stroke={color}
          strokeWidth='16.331'
          strokeMiterlimit='10'
        />
        <path
          d='M412.003 140.443H216.032C197.993 140.443 183.37 155.066 183.37 173.105V369.076C183.37 387.115 197.993 401.738 216.032 401.738H412.003C430.042 401.738 444.665 387.115 444.665 369.076V173.105C444.665 155.066 430.042 140.443 412.003 140.443Z'
          stroke={color}
          strokeWidth='16.331'
          strokeMiterlimit='10'
        />
        <path
          d='M66.3313 232.277L9.17285 239.844L30.5936 401.738L184.186 381.406'
          stroke={color}
          strokeWidth='16.331'
          strokeMiterlimit='10'
        />
        <path
          d='M108.547 353.072C111.553 353.072 113.99 350.635 113.99 347.628C113.99 344.622 111.553 342.185 108.547 342.185C105.54 342.185 103.103 344.622 103.103 347.628C103.103 350.635 105.54 353.072 108.547 353.072Z'
          stroke={color}
          strokeWidth='16.331'
          strokeMiterlimit='10'
        />
        <path
          d='M113.528 390.715C116.534 390.715 118.971 388.278 118.971 385.271C118.971 382.265 116.534 379.827 113.528 379.827C110.521 379.827 108.084 382.265 108.084 385.271C108.084 388.278 110.521 390.715 113.528 390.715Z'
          stroke={color}
          strokeWidth='16.331'
          strokeMiterlimit='10'
        />
        <path
          d='M189.113 163.769L74.7416 148.799L53.5386 310.72L183.914 327.786'
          stroke={color}
          strokeWidth='16.331'
          strokeMiterlimit='10'
        />
        <path
          d='M152.695 208.733C155.701 208.733 158.138 206.296 158.138 203.29C158.138 200.283 155.701 197.846 152.695 197.846C149.688 197.846 147.251 200.283 147.251 203.29C147.251 206.296 149.688 208.733 152.695 208.733Z'
          stroke={color}
          strokeWidth='16.331'
          strokeMiterlimit='10'
        />
        <path
          d='M157.622 171.063C160.628 171.063 163.066 168.626 163.066 165.62C163.066 162.613 160.628 160.176 157.622 160.176C154.615 160.176 152.178 162.613 152.178 165.62C152.178 168.626 154.615 171.063 157.622 171.063Z'
          stroke={color}
          strokeWidth='16.331'
          strokeMiterlimit='10'
        />
        <path
          d='M147.768 246.403C150.775 246.403 153.212 243.966 153.212 240.96C153.212 237.953 150.775 235.516 147.768 235.516C144.762 235.516 142.325 237.953 142.325 240.96C142.325 243.966 144.762 246.403 147.768 246.403Z'
          stroke={color}
          strokeWidth='16.331'
          strokeMiterlimit='10'
        />
        <path
          d='M142.842 284.074C145.848 284.074 148.285 281.636 148.285 278.63C148.285 275.623 145.848 273.186 142.842 273.186C139.835 273.186 137.398 275.623 137.398 278.63C137.398 281.636 139.835 284.074 142.842 284.074Z'
          stroke={color}
          strokeWidth='16.331'
          strokeMiterlimit='10'
        />
        <path
          d='M137.915 321.716C140.922 321.716 143.359 319.279 143.359 316.273C143.359 313.266 140.922 310.829 137.915 310.829C134.909 310.829 132.472 313.266 132.472 316.273C132.472 319.279 134.909 321.716 137.915 321.716Z'
          stroke={color}
          strokeWidth='16.331'
          strokeMiterlimit='10'
        />
        <path
          d='M469.788 97.1655L444.666 125.663'
          stroke={color}
          strokeWidth='16.331'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M465.106 148.88L491.835 130.1'
          stroke={color}
          strokeWidth='16.331'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_252_2474'>
          <rect width='500' height='321.911' fill='white' transform='translate(0 89)' />
        </clipPath>
      </defs>
    </svg>
  );
};
