import Image from 'next/image';
import type { ImageIconProps } from './types';

export const IconMetamask = ({ width, height, className }: ImageIconProps) => {
  return (
    <Image
      src='/images/ui/icons/metamask.svg'
      width={width}
      height={height}
      alt='MetaMask'
      className={className}
    />
  );
};
