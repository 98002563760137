import type { IconProps } from './types';

export const IconQuestion = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M200.092 330.831H281.452C271.932 241.339 382.432 210.739 382.432 120.67C382.432 39.261 326.461 0 250.293 0C195.187 0 149.89 25.9815 117 63.5104L168.355 110.566C188.551 89.4919 211.055 75.0577 238.753 75.0577C271.643 75.0577 292.705 95.2656 292.705 127.021C292.705 187.933 184.801 228.637 200.092 330.831ZM240.772 500C274.528 500 299.052 473.441 299.052 439.088C299.052 404.734 274.24 378.176 240.772 378.176C207.305 378.176 183.07 404.734 183.07 439.088C183.07 473.441 207.882 500 240.772 500Z'
        fill={color}
      />
    </svg>
  );
};
