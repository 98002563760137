import type { IconProps } from './types';

export const IconPlace = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 501'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_100_9997)'>
        <path
          d='M249.734 20.8687C341.547 20.8687 412.164 86.734 412.164 187.359C412.164 360.713 249.734 475.672 249.734 475.672C249.734 475.672 87.3037 360.713 87.3037 187.359C87.3037 86.734 157.92 20.8687 249.734 20.8687Z'
          stroke={color}
          strokeWidth='40.6075'
          strokeMiterlimit='10'
        />
        <path
          d='M249.734 282.787C303.558 282.787 347.192 239.154 347.192 185.329C347.192 131.505 303.558 87.8711 249.734 87.8711C195.909 87.8711 152.276 131.505 152.276 185.329C152.276 239.154 195.909 282.787 249.734 282.787Z'
          stroke={color}
          strokeWidth='40.6075'
          strokeMiterlimit='10'
        />
        <path
          d='M249.734 20.8687C341.547 20.8687 412.164 86.734 412.164 187.359C412.164 360.713 249.734 475.672 249.734 475.672C249.734 475.672 87.3037 360.713 87.3037 187.359C87.3037 86.734 157.92 20.8687 249.734 20.8687Z'
          stroke={color}
          strokeWidth='40.6075'
          strokeMiterlimit='10'
        />
        <path
          d='M249.734 282.787C303.558 282.787 347.192 239.154 347.192 185.329C347.192 131.505 303.558 87.8711 249.734 87.8711C195.909 87.8711 152.276 131.505 152.276 185.329C152.276 239.154 195.909 282.787 249.734 282.787Z'
          stroke={color}
          strokeWidth='40.6075'
          strokeMiterlimit='10'
        />
      </g>
      <defs>
        <clipPath id='clip0_100_9997'>
          <rect width='365.467' height='500' fill='white' transform='translate(67 0.564941)' />
        </clipPath>
      </defs>
    </svg>
  );
};
