import Image from 'next/image';
import type { ImageIconProps } from './types';

export const IconInstagram = ({ width, height, className }: ImageIconProps) => {
  return (
    <Image
      src='/images/ui/icons/instagram.png'
      width={width}
      height={height}
      alt='Instagram'
      className={className}
    />
  );
};
