import type { ImageIconProps } from './types';

export const IconX = ({ width, height, className, fillColor = 'black' }: ImageIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M282.807 222.282L414.672 69H383.424L268.926 202.093L177.476 69H72L210.29 270.26L72 431H103.25L224.163 290.449L320.74 431H426.216L282.799 222.282H282.807ZM240.006 272.033L225.995 251.992L114.509 92.5242H162.507L252.477 221.22L266.488 241.261L383.439 408.546H335.441L240.006 272.041V272.033Z'
        fill={fillColor}
      />
    </svg>
  );
};
