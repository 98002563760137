import type { ImageIconProps } from './types';

export const IconAppleMusic = ({ width, height, className }: ImageIconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 50 50'
      fill='none'
      className={className}
    >
      <g clip-path='url(#clip0_8608_2927)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M49.9922 15.6403C49.9922 15.0431 49.9922 14.4458 49.9894 13.8486C49.9866 13.3458 49.9811 12.8431 49.9672 12.3403C49.938 11.2444 49.8727 10.1403 49.6783 9.05694C49.4811 7.95694 49.1575 6.93333 48.6491 5.93333C48.1491 4.95139 47.4964 4.05417 46.7172 3.275C45.938 2.49583 45.0394 1.84306 44.0575 1.34306C43.0589 0.834722 42.0352 0.5125 40.9366 0.313889C39.8533 0.119444 38.7477 0.0541667 37.6519 0.025C37.1491 0.0111111 36.6464 0.00555556 36.1436 0.00277778C35.5464 0 34.9491 0 34.3519 0H15.6325C15.0352 0 14.438 0 13.8408 0.00277778C13.338 0.00555556 12.8352 0.0111111 12.3325 0.025C11.2366 0.0541667 10.1311 0.119444 9.04774 0.313889C7.94774 0.511111 6.92552 0.834722 5.92691 1.34306C4.94497 1.84306 4.04635 2.49583 3.26719 3.275C2.48802 4.05417 1.83524 4.95139 1.33524 5.93333C0.82691 6.93333 0.503299 7.95694 0.306076 9.05694C0.111632 10.1403 0.0463542 11.2458 0.0171875 12.3403C0.00329861 12.8431 -0.00225694 13.3458 -0.00503472 13.8486C-0.0078125 14.4458 -0.0078125 15.0431 -0.0078125 15.6403V34.3583C-0.0078125 34.9569 -0.0078125 35.5542 -0.00503472 36.1514C-0.00225694 36.6542 0.00329861 37.1569 0.0171875 37.6597C0.0463542 38.7556 0.111632 39.8597 0.306076 40.9431C0.503299 42.0431 0.82691 43.0667 1.33524 44.0667C1.83524 45.0486 2.48802 45.9458 3.26719 46.725C4.04635 47.5042 4.94497 48.1569 5.92691 48.6569C6.92552 49.1653 7.94913 49.4875 9.04774 49.6861C10.1311 49.8806 11.2366 49.9458 12.3325 49.975C12.8352 49.9889 13.338 49.9944 13.8408 49.9972C14.438 50.0014 15.0352 50 15.6325 50H34.3505C34.9477 50 35.545 50 36.1422 49.9972C36.645 49.9944 37.1477 49.9889 37.6505 49.975C38.7464 49.9458 39.8519 49.8806 40.9352 49.6861C42.0352 49.4889 43.0575 49.1653 44.0561 48.6569C45.038 48.1569 45.9366 47.5042 46.7158 46.725C47.495 45.9458 48.1477 45.0486 48.6477 44.0667C49.1561 43.0667 49.4797 42.0431 49.6769 40.9431C49.8714 39.8597 49.9366 38.7542 49.9658 37.6597C49.9797 37.1569 49.9852 36.6542 49.988 36.1514C49.9922 35.5542 49.9908 34.9569 49.9908 34.3597V15.6403H49.9922Z'
          fill='url(#paint0_linear_8608_2927)'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M35.3393 7.63886C35.2185 7.64997 34.1449 7.84025 34.0157 7.86664L19.1546 10.8653H19.149C18.7615 10.9486 18.4574 11.0847 18.2226 11.2833C17.9393 11.5208 17.7824 11.8569 17.7226 12.2486C17.7101 12.3319 17.6893 12.5014 17.6893 12.7514C17.6893 12.7514 17.6893 27.9347 17.6893 31.3514C17.6893 31.7861 17.6546 32.2083 17.3601 32.568C17.0657 32.9278 16.7018 33.0361 16.2754 33.1222C15.9518 33.1875 15.6282 33.2528 15.3046 33.318C14.0768 33.5653 13.2782 33.7333 12.5546 34.0139C11.8629 34.2819 11.3449 34.6236 10.9324 35.0569C10.1143 35.9139 9.78236 37.0764 9.89625 38.1653C9.99347 39.0944 10.4115 39.9833 11.1296 40.6402C11.6143 41.0847 12.2199 41.4222 12.9337 41.5653C13.674 41.7139 14.4629 41.6625 15.6157 41.4291C16.2296 41.3055 16.8046 41.1125 17.3518 40.7889C17.8935 40.4694 18.3574 40.043 18.7199 39.5236C19.0837 39.0028 19.3185 38.4236 19.4476 37.8083C19.581 37.1736 19.6129 36.6 19.6129 35.9666V19.8347C19.6129 18.9708 19.8574 18.743 20.5546 18.5736C20.5546 18.5736 32.9074 16.0819 33.4837 15.9694C34.2879 15.8153 34.6671 16.0444 34.6671 16.8875V27.9C34.6671 28.3361 34.6615 28.7778 34.3657 29.1389C34.0712 29.4986 33.7074 29.6069 33.281 29.693C32.9574 29.7583 32.6337 29.8236 32.3101 29.8889C31.0824 30.1361 30.2837 30.3041 29.5601 30.5847C28.8685 30.8528 28.3504 31.1944 27.9379 31.6278C27.1199 32.4847 26.7587 33.6472 26.8726 34.7361C26.9699 35.6653 27.4171 36.5541 28.1351 37.2111C28.6199 37.6555 29.2254 37.9833 29.9393 38.1278C30.6796 38.2764 31.4685 38.2236 32.6212 37.9916C33.2351 37.868 33.8101 37.6833 34.3574 37.3597C34.899 37.0403 35.3629 36.6139 35.7254 36.0944C36.0893 35.5736 36.324 34.9944 36.4532 34.3791C36.5865 33.7444 36.5921 33.1708 36.5921 32.5375V8.95275C36.5921 8.09719 36.1407 7.56942 35.3379 7.63886H35.3393Z'
          fill='white'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_8608_2927'
          x1='24.9922'
          y1='49.8056'
          x2='24.9922'
          y2='1.07778'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#FA233B' />
          <stop offset='1' stop-color='#FB5C74' />
        </linearGradient>
        <clipPath id='clip0_8608_2927'>
          <rect width='50' height='50' fill='white' transform='translate(-0.0078125)' />
        </clipPath>
      </defs>
    </svg>
  );
};
