import type { IconProps } from './types';

export const IconNoImage = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 501'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_2599_29478)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M500.03 106.601V396.283C500.03 433.49 469.975 463.763 433.03 463.763H66.9697C64.7627 463.763 62.6212 463.49 60.4697 463.278L25.0758 498.929L4.27781 477.98L474.924 3.95459L495.722 24.899L470.227 50.5808C488.172 62.692 500.03 83.2526 500.03 106.601ZM470.616 106.601C470.616 91.3637 461.586 78.2879 448.677 72.2879L261.419 260.884L298.01 297.737L351.187 244.177C356.702 238.621 366.47 238.621 371.985 244.177L431.99 304.611C437.732 310.394 437.732 319.778 431.99 325.561C426.242 331.349 416.934 331.349 411.192 325.561L361.586 275.601L318.808 318.687L364.212 364.409C369.955 370.192 369.955 379.571 364.212 385.359C361.338 388.253 357.576 389.697 353.813 389.697C350.045 389.697 346.283 388.253 343.414 385.359L240.621 281.833L89.4041 434.136H433.03C453.753 434.136 470.616 417.157 470.616 396.283V106.601ZM168.071 187.813C173.813 182.025 183.121 182.025 188.869 187.813L220.5 219.672L199.702 240.621L178.47 219.232L88.8081 309.535C83.0657 315.323 73.7576 315.323 68.0101 309.535C62.2677 303.753 62.2677 294.369 68.0101 288.586L168.071 187.813ZM66.9697 68.7425C46.2475 68.7425 29.3839 85.7273 29.3839 106.601V396.283C29.3839 401.005 30.2829 405.505 31.8586 409.672L10.0253 431.662C3.69195 421.359 -0.0302734 409.268 -0.0302734 396.283V106.601C-0.0302734 69.3889 30.0253 39.1162 66.9697 39.1162H399.773L370.359 68.7425H66.9697Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_2599_29478'>
          <rect width='500' height='494.949' fill='white' transform='translate(0 4)' />
        </clipPath>
      </defs>
    </svg>
  );
};
