import type { IconProps } from './types';

export const IconArrow2Right = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_588_11083)'>
        <path
          d='M143.875 0L107 36.875L320.125 250L107 463.125L143.875 500L393.875 250L143.875 0Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_588_11083'>
          <rect width='286.875' height='500' fill={color} transform='translate(107)' />
        </clipPath>
      </defs>
    </svg>
  );
};
