import type { ImageIconProps } from './types';

export const IconSoundCloud = ({ width, height, className }: ImageIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <rect y='135' width='500' height='230.263' fill='url(#pattern0_2614_17321)' />
      <defs>
        <pattern
          id='pattern0_2614_17321'
          patternContentUnits='objectBoundingBox'
          width='1'
          height='1'
        >
          <use xlinkHref='#image0_2614_17321' transform='scale(0.0131579 0.0285714)' />
        </pattern>
        <image
          id='image0_2614_17321'
          width='76'
          height='35'
          xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAAAjCAYAAAA+NeykAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAATKADAAQAAAABAAAAIwAAAAAN2yd8AAADl0lEQVRoBe1Z0XXaQBC08/IfdRB1YDrwdWA6iDowJbgEOgglyBVE6YBUkHMHSgXODNyQRdwJAQJLkH1vtXOze3d7Iwn88P3duGyCdjPTcg28NOObh1MosIB7+HuLU7Q5fAK/SePBKUKbSKmcx7wCfjOW4aQ1PCVIV77CGhT+6s3hhF1F2VdH4Qv4VdkMp3HmRMT7hDg0X5j1Rw1zdM/DV3CZAzhUkC71hTY4JX46ZXIPc/OwhmIPSyaX+I6MS2Y7Js4lGF8z3vWi0YfDOGtwHH6NcOegSiwa27/zXucSTE3lppMp8A/43HCXhl+w4cspm/YhWIEGSrhEYj85LzDLTdbUJsehCxyDrTV07/AZK+bHrtqXYE9oQIKwl5wXmOXWTPp6SG16lW6ZWbey3ao+BNOqTiARs8A/JvKXpKfHbtaHYDEB8tCQROJwErghBH7JqJ8c2MFtrxjG7XOcPopVA5zMhmgP65C8OpPJDb4ErLAJvwSsvWFQwV/gHr5jbU+YR3W5M2ObsHfF4u2q3ZHbpTafe5HUWaimWNyEN/ob/Dd8Dt85U0owh2JO5oe5tQqD2hATg/fBfF/BwPLP6KeCb4mWEixHocwJID7CeWccvGl5ILY2MEW8AWOzBzRcwTdnomB8St7hc7gsF0BUsTMc5zRNgjRzrlk4sjFFW6hnCjYNg0IkokQiJQEsZ7GZdrXwCSdzPB0FWwFEvmoSRxHUxiznAptvsocBd1j5IKoLdkHB7NNiMfO0GLfO/PuLXuNrjlMejoLxHZU5ARMnBt8yXL2BFOy/dVcg+yjB6u49DquyKVjsIL6l5bZcy7Tx/vOVgv00J1saLOgFIjGWa4oeq4ksNQqqSj1hzUN3Oc2fUNQU3XeZPIKaV/ZIwSqCYDqsonhGK6K3iYBjc2yZfZLF2zX3zdecj4olN6ZgngAWOxD5iheYPZBfMcdftJYiV6qPX+7sM6nNgrvoCePrVJII5gX2xMrk7YF/BT51E2ytWWKQkNoU6ow/IHp4JiJEcrJlAIocVoGzweZrmwiYef7awQZkXgCxMngokL06uIevjE9YzLwhdXhFk1rBt0DYvA8cRZIpbzmv5AAj35IJ3PZ7l/qJ2qPwHt60VxAZvDKJEpgLW85jTKvXYXX1Bgsqz+ZkSwDeBP1cJP5Skb3M4YvYhjFRYnWHchkmzODcuA6TxZUYLwPHMIN7OHlZBlDAGS9lS2xE920b/gUkFOaiQtsdHAAAAABJRU5ErkJggg=='
        />
      </defs>
    </svg>
  );
};
