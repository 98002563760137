import type { IconProps } from './types';

export const IconArrowRight = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_482_20233)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.08484e-05 225.605L400.893 225.605L259.643 81.8405L289.643 36L500 250.041L289.643 464.082L259.643 418.242L400.893 274.477L1.65758e-05 274.477L2.08484e-05 225.605Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_482_20233'>
          <rect width='500' height='500' fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};
