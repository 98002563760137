import type { IconProps } from './types';

export const IconEye = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_1056_13769)'>
        <path
          d='M24.7103 24.0204L18.376 17.686'
          stroke={color}
          strokeWidth='1.34328'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15 19.0859C17.6378 19.0859 19.7761 16.9476 19.7761 14.3098C19.7761 11.672 17.6378 9.53369 15 9.53369C12.3622 9.53369 10.2239 11.672 10.2239 14.3098C10.2239 16.9476 12.3622 19.0859 15 19.0859Z'
          stroke={color}
          strokeWidth='1.34328'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M19.7761 21.7039C18.2836 22.2949 16.6746 22.6681 15 22.6681C7.08659 22.6681 0.671661 14.3099 0.671661 14.3099C0.671661 14.3099 7.08659 5.95166 15 5.95166C22.9135 5.95166 29.3284 14.3099 29.3284 14.3099C29.3284 14.3099 26.9194 17.4502 23.2299 19.88'
          stroke={color}
          strokeWidth='1.34328'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1056_13769'>
          <rect width='30' height='19.4119' fill='white' transform='translate(0 5.28003)' />
        </clipPath>
      </defs>
    </svg>
  );
};
