import type { IconProps } from './types';

export const IconSchedule = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_100_9977)'>
        <path
          d='M186.524 59.5238C186.524 38.4762 169.476 21.4286 148.429 21.4286C127.381 21.4286 110.333 38.4762 110.333 59.5238V97.6191C110.333 118.667 127.381 135.714 148.429 135.714C169.476 135.714 186.524 118.667 186.524 97.6191V59.5238Z'
          stroke={color}
          strokeWidth='42.8571'
          strokeMiterlimit='10'
        />
        <path
          d='M389.667 59.5238C389.667 38.4762 372.619 21.4286 351.571 21.4286C330.524 21.4286 313.476 38.4762 313.476 59.5238V97.6191C313.476 118.667 330.524 135.714 351.571 135.714C372.619 135.714 389.667 118.667 389.667 97.6191V59.5238Z'
          stroke={color}
          strokeWidth='42.8571'
          strokeMiterlimit='10'
        />
        <path
          d='M427.762 78.5714H389.667V97.619C389.667 118.667 372.619 135.714 351.571 135.714C330.524 135.714 313.476 118.667 313.476 97.619V78.5714H186.476V97.619C186.476 118.667 169.429 135.714 148.381 135.714C127.333 135.714 110.286 118.667 110.286 97.619V78.5714H72.1905C44.2381 78.5714 21.381 101.429 21.381 129.381V427.809C21.381 455.762 44.2381 478.619 72.1905 478.619H427.762C455.714 478.619 478.571 455.762 478.571 427.809V129.381C478.571 101.429 455.714 78.5714 427.762 78.5714Z'
          stroke={color}
          strokeWidth='42.8571'
          strokeMiterlimit='10'
        />
        <path
          d='M427.762 78.5714H389.667V97.619C389.667 118.667 372.619 135.714 351.571 135.714C330.524 135.714 313.476 118.667 313.476 97.619V78.5714H186.476V97.619C186.476 118.667 169.429 135.714 148.381 135.714C127.333 135.714 110.286 118.667 110.286 97.619V78.5714H72.1905C44.2381 78.5714 21.381 101.429 21.381 129.381V211.905H478.524V129.381C478.524 101.429 455.667 78.5714 427.714 78.5714H427.762Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_100_9977'>
          <rect width='500' height='500' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
