import type { IconProps } from './types';

export const IconLottery = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_130_6067)'>
        <path
          d='M250.219 230.469C267.478 230.469 281.469 216.478 281.469 199.219C281.469 181.96 267.478 167.969 250.219 167.969C232.96 167.969 218.969 181.96 218.969 199.219C218.969 216.478 232.96 230.469 250.219 230.469Z'
          stroke={color}
          strokeWidth='23.4375'
          strokeMiterlimit='10'
        />
        <path
          d='M145.258 289.922C162.517 289.922 176.508 275.931 176.508 258.672C176.508 241.413 162.517 227.422 145.258 227.422C127.999 227.422 114.008 241.413 114.008 258.672C114.008 275.931 127.999 289.922 145.258 289.922Z'
          stroke={color}
          strokeWidth='23.4375'
          strokeMiterlimit='10'
        />
        <path
          d='M176.508 152.344C193.767 152.344 207.758 138.353 207.758 121.094C207.758 103.835 193.767 89.8438 176.508 89.8438C159.249 89.8438 145.258 103.835 145.258 121.094C145.258 138.353 159.249 152.344 176.508 152.344Z'
          stroke={color}
          strokeWidth='23.4375'
          strokeMiterlimit='10'
        />
        <path
          d='M354.594 292.969C371.853 292.969 385.844 278.978 385.844 261.719C385.844 244.46 371.853 230.469 354.594 230.469C337.335 230.469 323.344 244.46 323.344 261.719C323.344 278.978 337.335 292.969 354.594 292.969Z'
          stroke={color}
          strokeWidth='23.4375'
          strokeMiterlimit='10'
        />
        <path
          d='M320.531 199.219C337.79 199.219 351.781 185.228 351.781 167.969C351.781 150.71 337.79 136.719 320.531 136.719C303.272 136.719 289.281 150.71 289.281 167.969C289.281 185.228 303.272 199.219 320.531 199.219Z'
          stroke={color}
          strokeWidth='23.4375'
          strokeMiterlimit='10'
        />
        <path
          d='M404.789 488.281V483.086C404.789 460.156 386.039 441.406 363.109 441.406H133.93C111 441.406 92.25 460.156 92.25 483.086V488.281H404.75H404.789Z'
          stroke={color}
          strokeWidth='23.4375'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M250.219 386.719C353.772 386.719 437.719 302.772 437.719 199.219C437.719 95.6654 353.772 11.7188 250.219 11.7188C146.665 11.7188 62.7188 95.6654 62.7188 199.219C62.7188 302.772 146.665 386.719 250.219 386.719Z'
          stroke={color}
          strokeWidth='23.4375'
          strokeMiterlimit='10'
        />
        <path
          d='M133.93 441.406L250.219 199.219L363.109 441.406'
          stroke={color}
          strokeWidth='23.4375'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M250.219 199.219H179.906'
          stroke={color}
          strokeWidth='23.4375'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M250.219 199.219V128.906'
          stroke={color}
          strokeWidth='23.4375'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M250.219 199.219H320.531'
          stroke={color}
          strokeWidth='23.4375'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M250.219 199.219V269.531'
          stroke={color}
          strokeWidth='23.4375'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_130_6067'>
          <rect width='398.438' height='500' fill='white' transform='translate(51)' />
        </clipPath>
      </defs>
    </svg>
  );
};
