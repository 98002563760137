import type { IconProps } from './types';

export const IconList = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_1056_13715)'>
        <path
          d='M9.61761 8.20581H27.2647'
          stroke={color}
          strokeWidth='1.76471'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.20588 9.67653C6.01806 9.67653 6.67647 9.01812 6.67647 8.20594C6.67647 7.39376 6.01806 6.73535 5.20588 6.73535C4.3937 6.73535 3.73529 7.39376 3.73529 8.20594C3.73529 9.01812 4.3937 9.67653 5.20588 9.67653Z'
          stroke={color}
          strokeWidth='1.76471'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.61761 15.0686H27.2647'
          stroke={color}
          strokeWidth='1.76471'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.20588 16.5393C6.01806 16.5393 6.67647 15.8809 6.67647 15.0687C6.67647 14.2565 6.01806 13.5981 5.20588 13.5981C4.3937 13.5981 3.73529 14.2565 3.73529 15.0687C3.73529 15.8809 4.3937 16.5393 5.20588 16.5393Z'
          stroke={color}
          strokeWidth='1.76471'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.61761 21.9314H27.2647'
          stroke={color}
          strokeWidth='1.76471'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.20588 23.4019C6.01806 23.4019 6.67647 22.7435 6.67647 21.9313C6.67647 21.1191 6.01806 20.4607 5.20588 20.4607C4.3937 20.4607 3.73529 21.1191 3.73529 21.9313C3.73529 22.7435 4.3937 23.4019 5.20588 23.4019Z'
          stroke={color}
          strokeWidth='1.76471'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1056_13715'>
          <rect width='25' height='18.1373' fill={color} transform='translate(3 6)' />
        </clipPath>
      </defs>
    </svg>
  );
};
