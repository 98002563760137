import type { IconProps } from './types';

export const IconArtist = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_100_9955)'>
        <path
          d='M250.456 232.456C308.591 232.456 355.719 185.328 355.719 127.193C355.719 69.0577 308.591 21.9298 250.456 21.9298C192.321 21.9298 145.193 69.0577 145.193 127.193C145.193 185.328 192.321 232.456 250.456 232.456Z'
          stroke={color}
          strokeWidth='43.8596'
          strokeMiterlimit='10'
        />
        <path
          d='M460.982 403.509C460.982 461.623 366.728 478.07 250.456 478.07C134.184 478.07 39.9298 461.623 39.9298 403.509C39.9298 345.395 134.184 293.86 250.456 293.86C366.728 293.86 460.982 345.395 460.982 403.509Z'
          stroke={color}
          strokeWidth='43.8596'
          strokeMiterlimit='10'
        />
      </g>
      <defs>
        <clipPath id='clip0_100_9955'>
          <rect width='464.912' height='500' fill='white' transform='translate(18)' />
        </clipPath>
      </defs>
    </svg>
  );
};
