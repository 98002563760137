import type { ImageIconProps } from './types';

export const IconWebsite = ({ width, height, className }: ImageIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M426.777 73.2227C473.994 120.439 500 183.223 500 250C500 316.777 473.994 379.561 426.777 426.777C379.561 473.994 316.777 500 250 500C183.223 500 120.439 473.994 73.2227 426.777C26.0059 379.561 0 316.777 0 250C0 183.223 26.0059 120.439 73.2227 73.2227C120.439 26.0059 183.223 0 250 0C316.777 0 379.561 26.0059 426.777 73.2227ZM404.678 404.678C435.469 373.887 455.938 335.537 464.385 293.809C457.803 303.496 451.514 307.09 447.617 285.41C443.604 250.068 411.143 272.646 390.723 260.088C369.238 274.57 320.947 231.934 329.15 280.02C341.816 301.709 397.51 250.986 369.746 296.885C352.031 328.926 304.98 399.883 311.104 436.66C311.875 490.244 256.348 447.832 237.217 430.059C224.346 394.453 232.832 332.217 199.18 314.785C162.656 313.203 131.309 309.883 117.148 269.053C108.633 239.844 126.211 196.348 157.52 189.639C203.34 160.85 219.717 223.359 262.695 224.521C276.035 210.557 312.412 206.123 315.43 190.469C287.236 185.488 351.201 166.758 312.734 156.104C291.504 158.604 277.832 178.115 289.121 194.658C247.988 204.248 246.67 135.137 207.129 156.934C206.123 191.396 142.568 168.105 185.137 161.123C199.766 154.736 161.279 136.211 182.07 139.58C192.285 139.023 226.67 126.973 217.363 118.877C236.514 106.992 252.598 147.334 271.338 117.959C284.863 95.3711 265.664 91.2012 248.711 102.646C239.15 91.9434 265.596 68.8184 288.916 58.8281C296.689 55.498 304.111 53.6816 309.785 54.1992C321.533 67.7734 343.262 70.1172 344.395 52.5684C315.303 38.6328 283.223 31.2793 250.02 31.2793C202.354 31.2793 157.012 46.4258 119.502 74.4336C129.59 79.0527 135.312 84.8047 125.596 92.1582C118.047 114.648 87.4219 144.834 60.5469 140.557C46.5918 164.629 37.3926 191.143 33.4668 218.936C55.9863 226.387 61.1719 241.133 56.3379 246.055C44.8633 256.055 37.8125 270.244 34.1797 285.762C41.5039 330.605 62.5879 371.934 95.3418 404.697C136.66 446.016 191.592 468.77 250.02 468.77C308.447 468.77 363.379 446.016 404.697 404.697L404.678 404.678Z'
        fill='black'
      />
    </svg>
  );
};
