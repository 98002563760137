import type { IconProps } from './types';

export const IconPlus = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 52 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_203_28)'>
        <mask id='mask0_203_28' maskUnits='userSpaceOnUse' x='0' y='0' width='52' height='52'>
          <path d='M52 0H0V52H52V0Z' fill='white' />
        </mask>
        <g mask='url(#mask0_203_28)'>
          <path
            d='M48 22H30V4C30 1.8 28.2 0 26 0C23.8 0 22 1.8 22 4V22H4C1.8 22 0 23.8 0 26C0 28.2 1.8 30 4 30H22V48C22 50.2 23.8 52 26 52C28.2 52 30 50.2 30 48V30H48C50.2 30 52 28.2 52 26C52 23.8 50.2 22 48 22Z'
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_203_28'>
          <rect width='52' height='52' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
