import type { IconProps } from './types';

export const IconTickets = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_130_5002)'>
        <path
          d='M409.034 193.956C407.938 191.513 407.053 188.985 406.337 186.33C400.649 165.095 409.413 143.396 426.561 131.599L406.168 55.5052L15.4631 160.165L80.8967 404.372L82.877 403.824'
          stroke={color}
          strokeWidth='25.2802'
          strokeMiterlimit='10'
        />
        <path
          d='M151.091 194.209C149.869 189.701 152.566 185.108 157.032 183.886C161.498 182.665 166.133 185.361 167.355 189.827'
          stroke={color}
          strokeWidth='16.8535'
          strokeMiterlimit='10'
        />
        <path
          d='M144.013 143.649C148.667 143.649 152.44 139.876 152.44 135.222C152.44 130.568 148.667 126.795 144.013 126.795C139.359 126.795 135.586 130.568 135.586 135.222C135.586 139.876 139.359 143.649 144.013 143.649Z'
          stroke={color}
          strokeWidth='16.8535'
          strokeMiterlimit='10'
        />
        <path
          d='M453.653 320.273C453.653 298.279 467.726 279.614 487.36 272.662V193.872H82.877V446.674H487.36V367.884C467.726 360.932 453.653 342.267 453.653 320.273Z'
          stroke={color}
          strokeWidth='25.2802'
          strokeMiterlimit='10'
        />
        <path
          d='M213.491 270.26C218.145 270.26 221.918 266.488 221.918 261.834C221.918 257.18 218.145 253.407 213.491 253.407C208.837 253.407 205.064 257.18 205.064 261.834C205.064 266.488 208.837 270.26 213.491 270.26Z'
          stroke={color}
          strokeWidth='16.8535'
          strokeMiterlimit='10'
        />
        <path
          d='M213.491 211.484C218.145 211.484 221.918 207.711 221.918 203.057C221.918 198.403 218.145 194.63 213.491 194.63C208.837 194.63 205.064 198.403 205.064 203.057C205.064 207.711 208.837 211.484 213.491 211.484Z'
          stroke={color}
          strokeWidth='16.8535'
          strokeMiterlimit='10'
        />
        <path
          d='M213.491 329.079C218.145 329.079 221.918 325.306 221.918 320.652C221.918 315.998 218.145 312.226 213.491 312.226C208.837 312.226 205.064 315.998 205.064 320.652C205.064 325.306 208.837 329.079 213.491 329.079Z'
          stroke={color}
          strokeWidth='16.8535'
          strokeMiterlimit='10'
        />
        <path
          d='M213.491 387.898C218.145 387.898 221.918 384.125 221.918 379.471C221.918 374.817 218.145 371.044 213.491 371.044C208.837 371.044 205.064 374.817 205.064 379.471C205.064 384.125 208.837 387.898 213.491 387.898Z'
          stroke={color}
          strokeWidth='16.8535'
          strokeMiterlimit='10'
        />
        <path
          d='M213.491 446.674C218.145 446.674 221.918 442.901 221.918 438.247C221.918 433.593 218.145 429.821 213.491 429.821C208.837 429.821 205.064 433.593 205.064 438.247C205.064 442.901 208.837 446.674 213.491 446.674Z'
          stroke={color}
          strokeWidth='16.8535'
          strokeMiterlimit='10'
        />
      </g>
      <defs>
        <clipPath id='clip0_130_5002'>
          <rect width='500' height='419.314' fill='white' transform='translate(0 40)' />
        </clipPath>
      </defs>
    </svg>
  );
};
