import type { IconProps } from './types';

export const IconArrow2Left = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_118_26194)'>
        <g clipPath='url(#clip1_118_26194)'>
          <path
            d='M357 500L393.875 463.125L180.75 250L393.875 36.875L357 -3.22371e-06L107 250L357 500Z'
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_118_26194'>
          <rect width='500' height='500' fill={color} />
        </clipPath>
        <clipPath id='clip1_118_26194'>
          <rect
            width='286.875'
            height='500'
            fill={color}
            transform='translate(393.875 500) rotate(-180)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
