import type { IconProps } from './types';

export const IconCopy = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_118_24587)'>
        <path
          d='M408.589 403.226C432.258 403.226 451.613 383.871 451.613 360.202V91.4113L376.331 16.129H172.016C148.347 16.129 128.992 35.4839 128.992 59.1532'
          stroke={color}
          strokeWidth='32.2581'
          strokeMiterlimit='10'
        />
        <path
          d='M370.968 172.056L295.686 96.7742H91.4114C67.742 96.7742 48.3872 116.129 48.3872 139.798V440.887C48.3872 464.556 67.742 483.911 91.4114 483.911H327.984C351.653 483.911 371.008 464.556 371.008 440.887V172.056H370.968Z'
          stroke={color}
          strokeWidth='32.2581'
          strokeMiterlimit='10'
        />
        <path
          d='M376.331 16.129V91.4113H451.613'
          stroke={color}
          strokeWidth='32.2581'
          strokeMiterlimit='10'
        />
        <path
          d='M290.323 96.7742V172.056H365.605'
          stroke={color}
          strokeWidth='32.2581'
          strokeMiterlimit='10'
        />
      </g>
      <defs>
        <clipPath id='clip0_118_24587'>
          <rect width='435.484' height='500' fill='white' transform='translate(32.2581)' />
        </clipPath>
      </defs>
    </svg>
  );
};
