import type { IconProps } from './types';

export const IconHamburger = ({ width, height, color, className }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 18 18'
      className={className}
    >
      <g>
        <rect y='3.8' width='18' height='1.8' fill={color} />
        <rect y='8.1' width='18' height='1.8' fill={color} />
        <rect y='12.4' width='18' height='1.8' fill={color} />
      </g>
    </svg>
  );
};
