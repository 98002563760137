import type { IconProps } from './types';

export const IconHearts = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 501 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M405.992 454.269C441.972 433.338 460.501 417.946 474.472 394.22C488.443 370.495 490.992 339.76 474.668 323.779C458.344 307.799 431.139 307.407 415.256 323.779C407.315 332.015 404.472 337.064 404.472 337.064C404.472 337.064 401.482 332.064 393.344 324.073C377.021 308.044 349.864 309.171 333.982 325.593C318.099 342.015 321.433 372.652 336.041 396.034C350.599 419.417 369.521 434.319 406.041 454.319L405.992 454.269Z'
        stroke={color}
        strokeWidth='28.6765'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M433.05 311.769C439.864 302.407 446.237 292.603 452.315 282.26C492.756 213.534 500.158 124.564 452.952 78.191C405.746 31.8185 326.923 30.691 280.893 78.289C257.903 102.064 249.619 116.769 249.619 116.769C249.619 116.769 240.991 102.309 217.364 79.1224C170.158 32.7008 91.4327 35.8871 45.4033 83.4361C-0.626141 131.034 9.07974 219.76 51.2856 287.456C93.4915 355.103 148.246 398.289 254.031 456.23C287.56 436.72 315.893 418.877 340.158 401.328'
        stroke={color}
        strokeWidth='29.4118'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
