import type { IconProps } from './types';

export const IconArrowUpperRight = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_482_20232)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M425.724 400.546V121.695L47.4192 500L0 452.581L378.305 74.2761H99.4545L84.5573 0H500V415.443L425.724 400.546Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_482_20232'>
          <rect width='500' height='500' fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};
