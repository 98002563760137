import type { IconProps } from './types';

export const IconIntroduction2 = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_252_2425)'>
        <path
          d='M417.472 180.478L317.078 267.949C317.078 267.949 255.477 248.174 237.977 241.32C220.477 234.466 215.168 216.994 232.64 210.141C250.112 203.287 453.651 119.691 469.944 112.809C488.96 105.197 495.028 121.938 489.719 136.376C484.41 150.815 401.489 349.691 393.118 369.466C384.747 389.242 368.792 379.354 362.696 364.916C356.601 350.478 340.646 303.708 340.646 303.708'
          stroke={color}
          strokeWidth='16.8539'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M194.129 252.978L154.494 224.101L114.859 252.978L130 206.264L90.3931 177.388H139.354L154.494 130.674L169.635 177.388H218.595L178.989 206.264L194.129 252.978Z'
          stroke={color}
          strokeWidth='16.8539'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M300.562 181.461V125.843C300.562 105.253 283.764 88.427 263.23 88.427H45.7582C25.2245 88.427 8.42676 105.281 8.42676 125.843V257.781C8.42676 278.371 25.2245 295.197 45.7582 295.197H79.4661V346.826L130.393 295.197H263.258C282.163 295.197 297.921 280.899 300.281 262.556'
          stroke={color}
          strokeWidth='16.8539'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M269.326 318.371L233.174 348.062'
          stroke={color}
          strokeWidth='16.8539'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M303.034 352.079L230.73 411.461'
          stroke={color}
          strokeWidth='16.8539'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_252_2425'>
          <rect width='500' height='339.888' fill='white' transform='translate(0 80)' />
        </clipPath>
      </defs>
    </svg>
  );
};
