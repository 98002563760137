import type { IconProps } from './types';

export const IconBell = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M14.6774 24.5672C20.5401 24.5672 26.0004 23.0988 25.2927 21.9134C24.3948 20.5002 22.86 19.4321 22.86 11.0769C22.86 6.43268 19.1668 4 14.6774 4C10.188 4 6.49472 6.43268 6.49472 11.0769C6.49472 19.4321 4.95991 20.5002 4.06203 21.9134C3.35434 23.0988 8.81461 24.5672 14.6774 24.5672Z'
        stroke={color}
        strokeWidth='1.63636'
        strokeMiterlimit='10'
      />
      <path
        d='M17.3317 24.3462C17.3317 25.8124 16.1441 27 14.6779 27C13.2116 27 12.024 25.8124 12.024 24.3462'
        stroke={color}
        strokeWidth='1.63636'
        strokeMiterlimit='10'
      />
    </svg>
  );
};
