import type { IconProps } from './types';

export const IconPlaceFill = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 501 501'
      className={className}
    >
      <path
        fill={color}
        d='M250.7,0.7C159.2,0.7,84,72,84,173.7c0,36.3,8.6,61.6,24.4,89.5l131,230.8c2.3,4.1,6.6,6.6,11.2,6.6
	c4.7,0,9-2.5,11.2-6.6l131-230.8c15.9-28,24.4-53.2,24.4-89.5C417.3,72,342.1,0.7,250.7,0.7z M250.7,90.4
	c42.5,0,76.9,34.4,76.9,76.9c0,42.5-34.4,76.9-76.9,76.9s-76.9-34.4-76.9-76.9C173.7,124.8,208.2,90.4,250.7,90.4z'
      />
    </svg>
  );
};
